@charset "utf-8";
@import "tools/_settings";
@import "tools/_mixins";

.m-breadcrumb__list{
	padding: 0;
	margin: 0;
	font-weight: 500;
}
.m-breadcrumb {
  margin:42px auto 33px auto;
} 

.m-breadcrumb li {
  display: inline-block;
  font-size: 11px;
  color: #16496a;
 
}

.m-breadcrumb li:first-child a {
  padding-left: 0; }

.m-breadcrumb li a {
  
  display: inline-block;
  text-decoration: none;
  padding: 0 5px 0 0;
  font-weight: 500; }

.m-breadcrumb li a:hover {
  text-decoration: underline; }


@include min-screen($medium){
	.m-breadcrumb{
		overflow: hidden;
	}
	.m-breadcrumb__list{
		float: left;
	}
}

@include screen($medium,$large){
	.m-breadcrumb{
		margin-top: 26px;
    	margin-bottom: 17px;
	}
}

@include max-screen($medium){
	.m-breadcrumb{
		display: none;
	}
	.m-breadcrumb__list{
		display: none;
	}
	
}