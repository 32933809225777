@charset 'utf-8';
// ==========================================================================
// tools
// ==========================================================================
@import 'base/_reset';
@import 'tools/_settings';
@import 'tools/_mixins';
@import 'tools/_lang';


html
{
    font-size: 62.5%;

    -webkit-text-size-adjust: 100%;
    @extend %baseFontStyle;
}
body
{
    font-size: 1.4rem;
}
a
{
    color: inherit;
}
img
{
    vertical-align: top;
}
ol,
ul
{
    list-style: none;
}
.for-tab
{
    display: none;
}

.for-sp
{
    display: none;
}

@include max-screen($medium)
{
    .for-pc
    {
        display: none;
    }
    .for-tab
    {
        display: block;
    }
}

@include max-screen($small)
{
    .for-sp
    {
        display: block;
    }
}

.m-container
{
    max-width: 700px;

}
@import 'modules/_breadcrumb';
@import 'modules/_margin';
// @import "modules/_thumb";


.m-contentsContainer{
    background-color: #fff;
}
.m-contentsWrap p:first-child
{
    margin-top: 0;
}

img
{
    max-width: 100%;
}
.m-textBox
{
    p
    {
        margin: 25px 0;
    }
}

.m-textBox-2
{
    p
    {
        margin: 15px 0;
    }
}


.m-list
{
    margin: 15px 0;
    li
    {
        position: relative;
	    padding-bottom: 5px;
	}
    &.is-noMargin li
    {
        margin: 0;
    }
}
.m-list-normal
{
    padding-left: 0;

    list-style: none;
}
.m-list-unordered
{	
	li{
	    padding-left: 15px;
	    padding-bottom: 13px;
		&:before {
		    content: "・";
		    position: absolute;
		    left: 0px;
		    color: #4489d0;
			}
	}
}

.m-list-orderd{
    width: 100%;
    list-style: none;
    counter-reset: ol_li; /* ol_li カウンタ*/
    & li:before {
            counter-increment: ol_li;
            content: counter(ol_li)".";
            color: #4489d0;
            display: inline-block;   
            width: 3%;
            box-sizing: border-box;
            vertical-align: top;
     }
    & span{
        display: inline-block;   
        width: 97%;
        vertical-align: top;
    }
}

.m-bold
{
    font-weight: bold;
}



.m-contents_inner
{
    margin-top: 0;
    margin-bottom: 100px;
}
// m-dl
.m-dlList
{
    .m-dl:nth-child(odd)
    {
        background-color: #f2f2f2;
    }
    dt
    {
        font-weight: bold;
    }
}

@include max-screen($small)
{
    .m-contents_inner
    {
        padding: 0 15px;
        margin-bottom: 80px;
    }

    .m-container
    {
        margin: 0 auto 0;
    }
}

@include min-screen($small)
{
    .m-dlList
    {
        display: table;

        width: 100%;
        margin: 60px 0;

        table-layout: fixed;
        .m-dl
        {
            padding: 13px 36px;
        }
        dt,
        dd
        {
            display: table-cell;

            vertical-align: middle;
        }

        dt
        {
            width: 171px;
        }
        dd
        {
        }
    }
    .m-container
    {
        margin-right: auto;
        margin-left: auto;
    }
}
@include max-screen($small)
{
    .m-dlList
    {
        margin: 30px 0;
        .m-dl
        {
            padding: 10px 20px;
        }
    }
    .m-list-orderd{
        & li:before { 
                width: 5%;
         }
        & span{  
            width: 95%;
        }
    }
}



// m-icoLink
.m-icoLink
{
    display: inline-block;
    overflow: hidden;

    margin: 5px 0;

    .m-icoLink_txt
    {
        display: block;
        padding-left: 40px;
        text-decoration: underline;
    }

    &.is-pdf:before
    {
        display: inline-block;
        width: 11px;
        height: 14px;
        margin-right: 5px;
        content: '';
        vertical-align: bottom;
        background: url(/common/v2/img/icon/ico-pdf.png) center center no-repeat;
        background-size: 100%;
    }
}



// m-link
.m-link
{
    color: $keyColor;
}


// m-topic
.m-topic
{
    margin: 20px 0;
    padding: 20px;

    background-color: #f5f5f5;
}


// m-anchor
.m-anchor
{
    position: relative;
}
@include max-screen($small)
{
    .m-anchor
    {
        top: -54px;

        display: block;
    }
}
@media screen and (min-width: 767px) and (max-width: 1024px)  {
    .m-anchor
    {
        top: -70px;
        display: block;
    }	
}

@media screen and (min-width: 1024px) and (max-width: 1364px)  {
    .m-anchor
    {
        top: -110px;

        display: block;
    }	
}
// m-align
.m-align
{
    overflow: hidden;
}

@include min-screen($small)
{
    .m-align-right
    {
        .m-align-target
        {
            float: right;
        }
    }
}
// m-grayBox
.m-grayBox
{
    background-color: #f2f2f2;
}
.m-outerBox
{
    padding: 30px 0;
}
@include max-screen($small)
{
    .m-outerBox
    {
        margin-right: -15px;
        margin-left: -15px;
        padding-right: 15px;
        padding-left: 15px;
    }
}


// m-recommend

.m-recommend_title
{
    font-size: 2.3rem;

    text-align: center;
}
@include min-screen($small)
{
    .m-recommend
    {
        margin-top: 40px;
        padding-top: 80px;
        padding-bottom: 80px;

        border-top: $borderLightGray;
    }
    .m-recommend_title
    {
        margin-bottom: 30px;
    }
}

@include max-screen($small)
{
    .m-recommend
    {
        margin-top: 40px;
        margin-right: -15px;
        margin-left: -15px;
        padding: 10px 15px 0 15px;

        background-color: #f2f2f2;
    }
    .m-recommend_title
    {
        font-size: 1.8rem;

        margin-bottom: 10px;
    }
}


// m-thumb
.m-thumb
{
    .m-thumb_img
    {
        margin-bottom: 10px;
    }
    .m-thumb_caption
    {
        font-size: 1.2rem;
        line-height: 1.5;

        margin: 10px 0;
    }
}

// icon
.m-fileInfo
{
    display: inline-block;

    margin-left: 7px;
}
.is-pdf:before
{
    margin-top: -4px;

    background-image: url(../img/icon/ico-pdf.png);
}
.m-file:before
{
    display: inline-block;

    width: 15px;
    height: 15px;
    margin-right: -3px;

    content: '';
    vertical-align: middle;

    background-repeat: no-repeat;
    background-size: contain;
    background-size: 15px 15px;
}
.m-file
{
    font-weight: normal;
}

.is-external:after,.is-external02:after
{
    display: inline-block;
    vertical-align: top;
    width: 10px;
    height: 8px;
    margin-left: 5px;
    margin-top: 5px;
    content: '';
    background-image: url(../img/icon/ico-external.png) ;
    background-repeat: no-repeat;
    background-size: contain; 
    vertical-align: top;
}
.is-external02:after{
    background-image: url(../img/icon/ico-external_wit.png) ;
}
// text
.m-textAlign-r
{
    text-align: right !important;
}
.m-textAlign-l
{
    text-align: left !important;
}
.m-textAlign-c
{
    text-align: center !important;
}

.m-small
{
    font-size: 80%;
}

// indent
.m-indent
{
    overflow: hidden;
}
.m-indent_mark
{
    display: inline-block;
}
.m-indent_text
{
    display: block;
}

.m-indent-1 .m-indent_text
{
    padding-left: 1.5em;
}
.m-indent-2 .m-indent_text
{
    padding-left: 3em;
}
.m-indent-3 .m-indent_text
{
    padding-left: 4.5em;
}
.m-indent-4 .m-indent_text
{
    padding-left: 6em;
}


.m-scrollbox-h
{
    overflow-x: auto;
    overflow-y:hidden;
    img{
    	vertical-align: top;
    }
}
.m-scrollbox-h_item
{
    min-width: 700px;
    margin-top:35px;
}

@include max-screen(733px)
{
    .m-scrollbox{
        position: relative;
        .m-scrollbox-h{
            &:before {
                content: "画像は左右にスクロールできます";
                position: absolute;		    
                border: 1px solid #16496a;
                font-weight: bold;
                font-size: 12px;
                padding: 0px 5px;
                color: #16496a;
                margin-bottom: 10px;
            }
        }
        & .m-scrollbtn{
                position: absolute;
                top:35px;
                content: "";
                width: 100%;
                height: 90%;
                background-color:rgba(0,0,0,0.3);
                background-image: url(../img/icon/ico-swip.png);
                background-repeat: no-repeat;
                background-position: center;
                z-index: 5;
        }
    }
}

.m-related
{
    border-top: 1px solid #e3e3e3;
    & .m-container{
        margin-bottom: 50px;
    }
}

.m-related_title
{
    font-size: 2.0rem;
    margin-bottom: 10px;
    color: #16496a;
}
.m-related
{
    margin-top: 50px;
    padding-top: 58px;
}
@include max-screen($small)
{
    // .m-related_title{
    // 	font-size: 2.0rem;
    // 	margin-bottom: 10px;
    // }
    .m-related
    {
        margin-top: 59px;
        padding-top: 28px;
        padding-right: 15px;
        padding-bottom: 28px;
        padding-left: 15px;
        
    }
}


// conversion
.conversion-item
{
    display: table;
}
.conversion-itemInner
{
    font-weight: bold;
    display: table-cell;
    height: 50px;
    text-align: center;
    vertical-align: middle;
    text-decoration: none;
    color: #fff;
    background-color: #4489d0;
    transition:0.1s linear;


    &:hover{
        background-color: #62a8ee;
    }

    & span{
        position: relative;
        &:before{
            content: "";
            /* display: inline-block; */
            width: 20px;
            height: 20px;
            /* margin-right: 15px; */
            background: url(/common/v2/img/icon/ico-contact.png) no-repeat;
            background-size: contain;
            vertical-align: middle;
            position: absolute;
            left: -30px;
            top: 0;
        }
    }
}
.m-conversion
{
    padding-top: 32px;
    padding-bottom: 32px;

    background-color: #051926;
}
@include min-screen($small)
{
    .sitemap-list li{
        margin-bottom: 7px;
    }
    .conversion-list
    {
        overflow: hidden;
    }
    .conversion-item
    {
        float: left;

        box-sizing: border-box;
        width: 33.33%;
        padding: 0 3px;
    }
}
@include max-screen($small)
{
    // .m-related_title{
    // 	font-size: 2.0rem;
    // 	margin-bottom: 10px;
    // }
    
    .conversion-item
    {
        width: 100%;
        margin-top: 13px;
    }
    .conversion-item:first-child
    {
        margin-top: 0;
    }
    .conversion-list
    {
        padding-right: 50px;
        padding-left: 50px;
    }
    .m-conversion
    {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}
.m-footer-sitemap
{
    background-color: #efefef;
}

.sitemap-category_title
{
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.sitemap-list li
{
    font-size: 1.3rem;
    margin-bottom: 10px;
    color: #051926;
    a
    {
        text-decoration: none;
        &:hover{
            text-decoration: underline;
        }
    }
    &:before
    {
        position: relative;

        display: inline-block;

        width: 8px;
        height: 2px;
        margin-right: 4px;

        content: '';
        vertical-align: middle;

        background-color: #b4b4b4;
    }
}
.sitemap-title
{
    font-size: 1.4rem;

    margin-bottom: 16px;

    color: #1c587e;
}
.sitemap-category_title
{
    margin-bottom: 10px;
    a
    {
        text-decoration: none;
    }
}
.m-footer-sitemap
{
    
}
.sitemap-contents{
    padding:20px;
}
@include min-screen($small)
{
    .sitemap-block
    {
        overflow: hidden;
    }
    .sitemap-category
    {
        float: left;

        width: 25%;
    }
    .sitemap-category
    {
        margin-bottom: 20px;
    }
    .sitemap-open{
        display: none;
    }
    .sitemap-contents{
        display: block !important;
    }
}

@include max-screen($small)
{
    .m-footer-sitemap{
        padding-bottom: 50px;
    }
    .sitemap-category
    {
        margin-bottom: 20px;
    }
    .sitemap-contents{
        display: none;
    }
    .sitemap-open{
        text-align: center;
        font-weight: bold;
        font-size: 1.5rem;
        height: 50px;
        line-height: 50px;
        border-bottom: 1px solid #fff;
        position: relative;
        background-color: #efefef;
    }

    .sitemap-open:after{
        content: "";
        display: block;
        width: 50px;
        height: 50px;
        background: url(../img/ico-plus-blue.png) center center no-repeat;
        background-size: 20px;
        position: absolute;
        right:0px;
        top: 0px;
    }
    .sitemap-category_title
    {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 10px;
    }
   .sitemap-open.is-open:after{
        background: url(../img/ico-minus-blue.png)center center no-repeat;
        content:""
    }
    .sitemap-title
    {
        font-size: 1.5rem;
    }
    .sitemap-list li
    {
        font-size: 1.3rem;
        margin-bottom: 10px;
    }

}



.m-lnav_backtop {
  opacity: 0;
  border-top: 1px solid #2d729c;
  bottom: -1px;
  position: fixed;
  width: 256px;
  margin-top: -30px; 
    .m-lnav_itemInner{
         background-color: #16496a !important;
         color: #fff !important;
    }

}
  .m-lnav_backtop:after {
    content: "";
    display: block;
    position: absolute;
    width: 15px;
    height: 9px;
    right: 22px;
    top: 50%;
    background-image: url(../img/icon/ico-arrow-top.png);
    margin-top: -7px;
    background-repeat: no-repeat;
    background-size: 15px auto; }

  .m-lnav_backtop.is-bottom {
    position: absolute;
    bottom: 99px;
    top: auto; }

.m-lnav_backtop.is-hide {
  display: none; }


.m-link-pdf{
    display: inline-block;
    color: #4489d0;
    font-size: 1.1rem;
    padding-left: 18px;
    background: url(/common/v2/img/icon/ico-pdf-link.png) left center no-repeat;
    background-size: 11px 14px;
    &:hover{
     text-decoration: underline;
    }
}
@import 'modules/_heading';
@import 'modules/_button';
@import 'modules/_layout';
@import 'modules/_link';
@import 'modules/_panel';
@import 'modules/_modal';
@import 'modules/_contents';
