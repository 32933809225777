
// brakepoints
$xlarge: 1920px; //pclarge
$large: 1280px; //pcsmall
$medium: 1024px; //tab
$small:768px; //sp

$jpFontfamily:'Noto Sans Japanese',sans-serif;
$enFontfamily:'Noto Sans',sans-serif;

$keyColor:#16496a;
$keyColorHover:#003c96;
$keyColorInverse:#fff;
$textColor:#051926;
$topicColor:#f2f2f2;
$navItemBorder:#2e719b;
$borderLightGray:1px solid #f2f2f2;

$easeOutCubic:cubic-bezier(0.215, 0.610, 0.355, 1.000);

