.unit-layout{
    overflow: hidden;
}
.unit-layout_box.is-1{
    width: 8.33333%;
  }
.unit-layout_box.is-2{
width: 16.66667%;
}

.unit-layout_box.is-3{
width: 25%;
}

.unit-layout_box.is-4{
width: 33.33333%;
}

.unit-layout_box.is-5{
width: 41.66667%;
}

.unit-layout_box.is-6{
width: 50%;
}

.unit-layout_box.is-7{
width: 58.33333%;
}

.unit-layout_box.is-8{
width: 66.66667%;
}

.unit-layout_box.is-9{
width: 75%;
}

.unit-layout_box.is-10{
width: 83.33333%;
}

.unit-layout_box.is-11{
width: 91.66667%;
}

.unit-layout_box.is-12{
width: 100%;
}

.unit-layout_box{
    box-sizing:border-box;
}
@include min-screen($small)
{
    .unit-layout-multi-01{
        overflow: hidden;

        .unit-layout_primary{
            float: left;
            &.unit-layout_box{
                padding-right: 20px;
            }
        }

        .unit-layout_secondary{
            float: right;
            &.unit-layout_box{
                padding-left: 20px;
            }
        }
    }
    .unit-layout-multi-02{
        .unit-layout_primary{
            float: right;
            &.unit-layout_box{
                padding-left: 20px;
            }
        }

        .unit-layout_secondary{
            float: left;
            &.unit-layout_box{
                padding-right: 20px;
            }
        }
    }
}


@include max-screen($small)
{
    .unit-layout_primary{
        
        &.unit-layout_box{
            margin-bottom: 10px;
        }
    }

    .unit-layout_secondary{
    
        &.unit-layout_box{
           
        }
    }

    .unit-layout_box{
        width: auto !important;
    }
}

@include max-screen($small)
{
}
