//レスポンシブなのでclass名に具体的なpx値は含まない。

@for $i from 0 through 24 {
  .m-mt-#{$i} { margin-top: 5px * $i !important; }
}


@for $i from 0 through 24 {
  .m-mb-#{$i} { margin-bottom: 5px * $i !important; }
}


//sp only
@include max-screen($small){
	@for $i from 0 through 24 {
	  .m-mt-small-#{$i} { margin-top: 5px * $i !important; }
	}


	@for $i from 0 through 24 {
	  .m-mb-small-#{$i} { margin-bottom: 5px * $i !important; }
	}

}



@for $i from 0 through 24 {
  .m-pl-#{$i} { padding-left: 5px * $i !important; }
}


@for $i from 0 through 24 {
  .m-pr-#{$i} { padding-right: 5px * $i !important; }
}


//sp only
@include max-screen($small){
	@for $i from 0 through 24 {
	  .m-pl-small-#{$i} { padding-left: 5px * $i !important; }
	}


	@for $i from 0 through 24 {
	  .m-pr-small-#{$i} { padding-right: 5px * $i !important; }
	}

}
