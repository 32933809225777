@charset "utf-8";
/*--文字そろえ--*/
	.m-t_right{
		text-align: right!important;
	}
	.m-t_left{
		text-align: left!important;
	}
	.m-t_center{
		text-align: center!important;
	}

	/*-電話icon-*/
	.m-tel_point{
		position: relative;
		padding-left: 19px;
		&:before{
		position: absolute;
		left: 0px;
		top: 3px;
		content: "";
		width: 14px;
		height: 14px;
		background: url("/common/v2/img/icon/ico-tel.png") no-repeat 100% center;
		background-size: 100%;
		}
	}
	/*-FAXicon-*/
	.m-fax_point{
		position: relative;
		padding-left: 19px;
		&:before{
		position: absolute;
		left: 0px;
		top: 3px;
		content: "";
		width: 14px;
		height: 14px;
		background: url("/common/v2/img/icon/ico-tel.png") no-repeat 100% center;
		background-size: 100%;
		}
	}
	/*--上下に灰色のボーダーを入れる見出し--*/
	.m-linkborder{
	    width: 100%;
	    padding: 10px;
	    margin: 10px auto;
		border: solid #e6e6e6;
		border-width:1px 0 1px 0;
		box-sizing: border-box;
		& span {
		display: inline-block;
		text-align: left;
		}
	}
  	/*--文字色灰色のテキスト--*/
	.m-txtsell-gry{
	  padding: 5px 0;
	  color:#969696;
	  display: block;
	}

	/*-数字表-*/
	.m-table_02{
		border-collapse: collapse;
		border-spacing: 0;
		width: auto;
		margin-top: 30px;
		& td,& th{
			margin:5px;
			padding: 10px 15px;
			font-size:1.4rem;
			text-align: left;
			vertical-align: top;
			border:0px;
			box-sizing: border-box;
		}
		& th{
			width:20%;

		}
		& td{ 
			position: relative;
			width:80%;
		}
		& tr:nth-child(even) {
			& td,& th{
			background-color: #f2f2f2;
			}
		}
		& a{
			text-decoration: none;
		}
	} 
/*--リンクの青文字--*/
.m-icbox-arrow{
	position: relative;
	color:#4489d0;
	text-align: right;
	text-decoration: none;
	&:after {
    display: inline-block;
    content: "";
    width: 12px;
    height: 8px;
    background: url(/common/v2/img/icon/ico-arrow.png)no-repeat center;
    background-size: contain;
	}	
}
a:hover .m-icbox-arrow{
    text-decoration: underline;
}
/*--リンクの青いボタン--*/
	.m-morebox{
		display: block;
		margin:30px -2px;
		width: auto;
		overflow:hidden;
		box-sizing: border-box;
		& a{
			min-width: 230px;
			position: relative;
			padding:15px;
			box-sizing: border-box;
			color:#fff;
			display: block;
			background-color:#014dad;;
			border-radius: 5px;
			text-decoration: none;
			line-height: 1.3rem;
				&:after{
				    position: absolute;
				    right: 15px;
				    top:17px;
				    content: "";
				    width: 10px;
				    height: 10px;
				    border: 1px solid;
				    border-color: transparent transparent #fff #fff;
				    transform:rotate(228deg);
				}
			}
		}
/*-news一覧-*/
.m-news_tag{ 
    background-color: #ccc;
    }
.tag-shop{
	background-color: #d0b144;
}
.tag-press{
	background-color:#7244d0;
}
.tag-information{
	background-color:#d04451;
}
	.m-list_news{
		margin:40px 0;
		display: inline-block;
		line-height: 1.8;
		width:100%;
			& .tag{
				width:90px;
				float: left;
				margin-right: 10px;
				text-align: center;
				color:#fff;
				font-size: 0.9rem;
				&:last-child{
					margin: 0px;
				}	
			}
			
		& li{
			list-style: none;
			padding: 15px 0;
			border-top:1px solid #e3e3e3;
			overflow: auto;
			&:last-child{
				border-bottom:1px solid #e3e3e3;
			}
			& a{
				text-decoration: none;
			}
			& p{
				float: left;
				width:100px; 
				height:100px;
				overflow: hidden;
				margin: 0px 15px 0px 0px;
				& img{
					width: 100%;
					height: 100px;
					object-fit: cover;
				}
			}
			& div{
				padding: 0px;
				min-width: 75px;
					span{
						&:nth-child(2){
							display: block;
							height:50px;
							overflow: hidden;
						}
						&:nth-child(3){
							display: block;
						}
					}
				}
			}
		}
/*--faq table--*/
    .m-faq-accordion {
	    & dl{
	    	pointer-events: auto;
	    	margin:0px;
	    }
	    & dt{
	    	pointer-events: auto;
		    display:block;
		    padding: 10px 32px;
		    background:url(/ir/finance/img/plus.png) center right 10px no-repeat;
		    background-size: 11px;
		  	border-bottom:#959ea5 1px solid;
			&.active{
			    background:url(/ir/finance/img/minus.png)  center right 10px no-repeat;
			    background-size: 11px;
			    border:none;
		    }
		  }
		& dd{
		    margin:10px 0px;
		    padding-bottom:20px;
		    height:auto;
		    width: auto;
		    display:none;
		    border-bottom:#959ea5 1px solid;
		   }
		/*-FAQのアイコン-*/
		& .m-faq-q{
			position: relative;
			&:before{
				position: absolute;
				content: "";
				left:0px;
				width:28px;
				height: 25px;
			 	background:url(/group/img/Q.png)  center no-repeat;
			}
		}
	}

	/*--2列のbox-*/
		.m-inlinebox {
			display: block;
			margin:30px -2px;
			width: auto;
			overflow:hidden;
			box-sizing: border-box;
		}
		.m-inlinebox ul li{
			padding: 2px;
			float: left;
			overflow: hidden;	
			list-style: none;
			box-sizing: border-box;
			& a{
				position: relative;
				padding:10px 0px 10px 45px;
				color:#fff;
				display: block;
				background-color:#16496a;
				text-decoration: none;
				transition:0.1s linear;
				&:hover{
					background-color:#1c587e;
				}
				&:after{
					position: absolute;
					top: 17px;
					left: 18px;
					right: 45%;
					content:"";
					width: 5px;
					height: 5px;
					border: 2px solid;
					border-color: transparent transparent #3887d7 #3887d7;
					-ms-transform: rotate(-45deg);
					transform: rotate(-45deg);
				}
			}
		}

@include min-screen($small){
	 .m-short{
        min-height:1200px;
    }
	.m-inlinebox ul li {
		width: 33.3%;
	}
}
@include max-screen($small){
	.m-inlinebox ul li {
	    width: 50%;
	    display: table;
		& a {
			padding:0 20px 0 45px;
			display: table-cell;
			width: 50%;
			height:50px;
			line-height:2rem; 
			vertical-align: middle;
			&:after {
				position: absolute;
				right: 15px;
				top: 34%;
			}
		}
	}

	.m-table_02 {
		& th,& td{
			width: 100%;
			display: block;
			margin: 0px;
		}
		& th,& td ,& td p{
			position: relative;
			padding-right: 50px;
		}
		& td, & th{
			padding: 10px;
		}
	}
	.m-table_02 .i-popbox{
		position: relative;
		&:after {
		    position: absolute;
		    right: 5px;
	    }
	}
	.m-table_03 .i-popbox:after {
	    position: absolute;
	    right: -5px;
    }
    /*--link btn--*/
	.m-morebox{
		& a{width: 100%;}
		}
	/*-news一覧-*/
	.m-list_news {
	    margin: 20px 0;
		& li {
			padding: 15px 0;
			margin:0;
		    border-top: 1px solid #e6e6e6;
			&:last-child{
			    border-bottom: 1px solid #e6e6e6;
			}
			& .tag {
				width: 23%;
				float: none;
				text-align: 0px;
				margin-right: 5px;
				display: inline-block;
				margin: 0px 1px;
				line-height: 16px;
				letter-spacing: 0px;
			}
			& div{
				padding: 0px;
				min-width: 75px;
				line-height: 1.7rem;
				display: block;
				position: relative;
				span{
					&:nth-child(2){
						height:50px;
						overflow: hidden;
					}
					&:nth-child(3){
						visibility:hidden
					}
				}
				aside{
					display:inline-block;
				}
			}
		}
	}
	/*-faq-*/
	.m-faq-accordion{ 
		& dt{
			padding: 10px 30px;
		}
		& .m-faq-q:before {
		    	top:20px;
		}
	}

}

