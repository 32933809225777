.m-heading{
	padding: 0;
	margin: 13px 0;
	font-weight: 500;
}
.m-heading-01{
	font-size: 2.8rem;
	line-height: 1.5;
	letter-spacing: 0.05em;
	margin-top: 0;
}
.m-heading-margin{
	margin-bottom: 60px;
}

/*--追加-*/
.m-inner_title{ 
	font-size: 1.4rem;
	color:#154a6a;
}
.m-next_title_head{
	color:#051926;
}
@include min-screen($small) {
	.m-heading-01{
		margin-top: 30px;
		padding-bottom: 15px;
	}
}

@include max-screen($small){
	.m-heading-01{
		font-size: 2.6rem;
	
		margin-bottom: 20px;
		margin-top: 30px;
	}

	.m-heading-margin{
		margin-bottom: 30px;
	}
}

.m-heading-02{
	font-size: 2.4rem;
	color: $keyColor;
	letter-spacing: 0.05em;
}


@include min-screen($small) {
	.m-heading-02{
		margin-top: 0px;
	
	}
}


@include max-screen($small){
	.m-heading-02{
		font-size: 1.8rem;
		padding-bottom: 3px;

	}
}


.m-heading-03{
	position: relative;
	font-size: 2.4rem;
	letter-spacing: 0.05em;
    padding-left: 16px;
	
    &:before{
    	top: 10px;
    	bottom: 10px;
    	content:"";
    	display: block;
    	position: absolute;
    	left: 0;
    	width: 6px;
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0.32, #16496a), color-stop(0.32, #4489d0));
		background: -webkit-linear-gradient(top, #4489d0 32%, #16496a 32%);
		background: -moz-linear-gradient(top, #4489d0 32%, #16496a 32%);
		background: -o-linear-gradient(top, #4489d0 32%, #16496a 32%);
		background: -ms-linear-gradient(top, #4489d0 32%, #16496a 32%);
		background: linear-gradient(to bottom, #4489d0 32%, #16496a 32%);
    }
}


@include min-screen($small) {
	.m-heading-03{
		margin-top: 66px;
	}
}


@include max-screen($small){
	.m-heading-03{
		font-size: 2.2rem;
		margin-top: 40px;
		&:before{
	    	top: 8px;
	    	bottom: 8px;
	    	height: 23px;
	    }
	}
	
}




.m-heading-04{
	font-size: 1.4rem;
	color: #16496a;
	letter-spacing: 0.05em;
	position: relative;
	padding-left: 20px;
	&:before{
		content:"■";
		display: block;
		position: absolute;
		left: 0;
		top: 0;
	}
}


@include min-screen($small) {
	.m-heading-04{
	}

}


@include max-screen($small){
	.m-heading-04{
		font-size: 1.6rem;
	}
}

.m-heading-05{
	font-size: 1.8rem;
	letter-spacing: 0.05em;
}


@include max-screen($medium) {
	

}


@include max-screen($small){
	.m-heading-05{
		font-size: 1.6rem;

	}
}


.m-heading-img{
	background-size: cover;
	background-position: center center;
	color: #fff;
	font-weight: 500;
	margin-top: 0;
	
}
.m-heading-img_contents{
	display: table;
	width: 100%;
	height: 100%;
}
.m-heading-img_inner{
	display: table-cell;
	vertical-align: middle;
	padding: 0 40px;
}
@include min-screen($small){
	.m-heading-img{
		
	    font-size: 3.0rem;  
        margin-bottom: 25px;
	}
	
	.m-heading-img_contents{
		max-width: 700px;
		margin: 0 auto;
		height: 125px;
	}

}



@include max-screen($small){
	.m-heading-img{
		height: 110px;
		margin-left: -15px;
		margin-right: -15px;
		font-size: 2.6rem; 
		padding-left: 15px;
		margin-bottom: 25px;
	}
	.m-heading-img_inner{
		padding-left: 0;
	}
}
