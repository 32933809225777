@charset "utf-8";

.modal-window
{
    position: fixed;
    z-index: 101;
    top: 0;
    left: 0;

    overflow: auto;

    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding-right: 15px;
    padding-left: 15px;

    cursor: pointer;

    background-color: rgba(0,0,0,.7);
}

.modal__body
{
    position: relative;
    top: 50%;

    width: 800px;
    max-width: 100%;
    padding-top: 70px;
    margin-right: auto;
    padding-bottom: 70px;
    margin-left: auto;
    

    cursor: default;
    -webkit-transform: translate(0,-50%);
       -moz-transform: translate(0,-50%);
        -ms-transform: translate(0,-50%);
         -o-transform: translate(0,-50%);
            transform: translate(0,-50%);

   
}
.modal__contents{
	background-color: #fff;

    position: relative;
}
.is-modal-show
{
    overflow: hidden;
}
.is-modal-overflow-window .modal__body
{
    top: 0 !important;

    -webkit-transform: translate(0,0);
       -moz-transform: translate(0,0);
        -ms-transform: translate(0,0);
         -o-transform: translate(0,0);
            transform: translate(0,0);
}
.modal-contents
{
    display: none;
}

.modal-close
{
    position: absolute;
    top: 30px;
    right: 0;
    
    img
    {
        width: 25px;
        height: 25px;
    }
}
.modal-youtube-container{
    position: relative;
  width: 100%;
  padding-top: 56.25%;
}
.modal-youtube-container iframe{
    position: absolute;
  top: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
}

@include max-screen(950px)
{
    .modal-close
    {
        top: 30px;
        right: 0;
    }
}
@include max-screen($small){
	
}
