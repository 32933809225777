.m-panel{
    text-decoration: none;
    box-sizing:border-box;
    display: block;
}
.m-panel_thumb{
    img{
        width: 100%;
    }
}
.m-panel_ttl{
    font-weight: bold;
}

.m-panel_txt{
    padding: 10px 13px 10px;
    font-size: 1.3rem;
    line-height: 1.5;
    position: relative;
}


.m-panel_description{
    color: #969696;
    line-height: 1.7;
}

.m-panel_inner{
    background-color: #f2f2f2;
}


@include min-screen($small)
{

    .m-panelList{
        overflow: hidden;
        margin:10px -10px;
        transition :0.1s linear;
        & a:hover{
            opacity: 0.8;
        }
        .m-panel{
            width: 33.33%;
            float: left;
            padding: 10px 10px;
            transition :0.1s linear;
            &:hover{
                opacity: 0.8;
                display: block;
            }
        }
    }
    .m-panel_ttl{
        font-size: 1.5rem;
        
    }
    .m-panel.has-tag .m-panel_txt{
        padding-bottom: 55px;
    }
}

@include max-screen($small)
{
    .m-panelList{
        .m-panel_inner{
            min-height: 68px;
        }
        .m-panel{
            margin-bottom: 10px;
        }
    }
    .m-panel_inner{
        display: table;
        width: 100%;

    }
    .m-panel_thumb{
        display: table-cell;
        vertical-align: middle;
        width: 100px;
    }
    .m-panel_txt{
        display: table-cell;
        vertical-align: middle;
        
    }
    .m-panel_description{
        display: none;
    }
}


.m-panel_description{
    margin: 5px 0;
}
.m-panel_tag{
    font-weight: bold;
    color: $keyColor;
    font-size:1.3rem;
    
}

@include min-screen($small){
    .m-panel_tag{
        position: absolute;
        bottom: 10px;
    }
    .m-panel_description{
    margin: 15px 0 2px;
    }
}