.mod-link-inline{
    display: inline-block;
    border-bottom: 1px solid;
    text-decoration: none;
}
.mod-link-inline.is-external:after{
    content:"";
    width: 10px;
    height: 10px;
    background-color: #f00;
    display: inline-block;
}
@include max-screen($medium)
{
}

@include max-screen($small)
{
    .mod-link-inline{
        display: block;
        width: 50%;
        float: left;
        border: 1px solid #f2f2f2;
        box-sizing:border-box;
        line-height: 50px;
        padding-left: 20px;
    }

    .mod-link-inline.is-large{
        width: 100%;
    }
}
