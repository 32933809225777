@mixin reset() {
  div, span, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  abbr, address, cite, code,
  del, dfn, em, img, ins, kbd, q, samp,
  small, strong, sub, sup, var,
  b, i,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section, summary,
  time, mark, audio, video {
      margin:0;
      padding:0;
      border:0;
      outline:0;
      font-size:100%;
      vertical-align:baseline;
      background:transparent;
  }
}

@mixin max-screen($break-point) {
  @media screen and (max-width: $break-point - 1),print {
    @content;
  }
}

@mixin min-screen($break-point) {
  @media screen and (min-width: $break-point),print {
    @content;
  }
}

@mixin screen($break-point-min, $break-point-max) {
  @media screen and (min-width: $break-point-min) and (max-width: $break-point-max - 1),print{
    @content;
  }
}

@mixin glowBox($color) {
    box-shadow:0px 0px 0 0 $color;
	-moz-box-shadow:0px 0px 0 0 $color;
	-webkit-box-shadow:0px 0px 0 0 $color;
}

@mixin glowText($color) {
    //text-shadow: 0px 0px 6px $color;  
}

