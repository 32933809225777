.m-btn
{
    line-height: 1.5;

    display: inline-block;
    text-decoration: none;
    font-size: 1.3rem;
    max-width: 100%;
    text-align: left;
    position: relative;
    border-radius: 3px;
    margin: 5px 0 5px 0;
    transition : 0.1s linear;
    &.is-icon:after{
        content:"";
        display: block;
        position: absolute;
        top: 50%;
        right: 20px;
        background-position: center center;
        background-size: contain;
    }
    &:hover{
        opacity: 0.7;
    }
}


.m-btn_inner
{
    display: flex;
    flex-direction: column;
	box-sizing:border-box;
    min-height: 45px;
    justify-content: center; /* 子要素をflexboxにより中央に配置する */
    padding: 0 30px 0 15px;


}
.m-btn.is-icon{
    .m-btn_inner{
        padding-right: 50px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.m-btn-full{
    display: block;
    width: 100%;
}

.m-btn-01{
    border:1px solid #999999;
}
.m-btn02-inrbox{
    width:auto;
    margin:0px -5px 50px;
    overflow: auto;
}
.m-btn02{
    width:50%;
    float: left;
    margin:0;
    padding:5px; 
    box-sizing:border-box;
}

.m-btn-blue{
    color: $textColor;
    font-weight: bold;
    & div{
        border:solid #d6e7f0 1px;
        & span{
           padding-left: 40px; 
            &:before{
                content: "";
                position: absolute;
                left: 22px;
                top: 15px;
                width: 28px;
                height: 28px;
                background-repeat: no-repeat;
                background-position: center;
            }
        }
    }
    &.is-arrow:after{
        width: 15px;
        height: 15px;
        background-image: url(../img/icon/ico-btn-arrow.png);
        background-repeat: no-repeat;
        background-size: 100%;
        margin-top: -7.5px;
        right: 14px;
    }
    &.is-arrow02:after{
        background-image: url(../img/icon/ico-win.png);
    }
}

@include max-screen($medium){
    .m-btn02{
        width:100%;
        float: none;
        margin:0;
        padding:5px; 
        box-sizing:border-box;
    }
}

@include max-screen($small)
{
    .m-buttonList{
        .m-btn{
            display: block;
        }
    }
    .m-btn{
        display: block;
    }
}
